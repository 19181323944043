
body{
  background-color: #87cefa;
  margin: 0;
  color: #333333;
}

p, h1,h2,h3{
  margin: 0;
}

.hidden{
  display: none;
}

ul{
    list-style: none;
  }
  img{
    max-width: 100%;
  }
  
  a{
    text-decoration: none;
    color: #333;
  }
  a:hover{
    opacity: .8;
  }
  